var render = function () {
  var _vm$match, _vm$match$opponent, _vm$match$opponent$pa, _vm$match2, _vm$match2$opponent, _vm$match2$opponent$p, _vm$match3, _vm$match3$opponent, _vm$match4, _vm$match4$opponent, _vm$match$opponent2, _vm$match5, _vm$match5$opponent, _vm$match6, _vm$match6$opponent, _vm$match7, _vm$match7$opponent, _vm$match8, _vm$match9, _vm$match10, _vm$match11, _vm$match12, _vm$match13, _vm$match13$result, _vm$match14, _vm$match14$result, _vm$match15, _vm$match15$result, _vm$match16, _vm$match16$result, _vm$match17, _vm$match18, _vm$match19, _vm$match20, _vm$match21, _vm$match22;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "h-100 w-100",
    attrs: {
      "sub-title": _vm.matchTitle,
      "body-class": "d-flex flex-column"
    }
  }, [_c('b-row', {
    staticClass: "flex-nowrap"
  }, [_c('b-col', {
    staticClass: "text-center",
    attrs: {
      "sm": "5"
    }
  }, [_c('b-avatar', {
    staticClass: "mb-2",
    attrs: {
      "src": (_vm$match = _vm.match) === null || _vm$match === void 0 ? void 0 : (_vm$match$opponent = _vm$match.opponent1) === null || _vm$match$opponent === void 0 ? void 0 : (_vm$match$opponent$pa = _vm$match$opponent.participant) === null || _vm$match$opponent$pa === void 0 ? void 0 : _vm$match$opponent$pa.avatar_image,
      "variant": "light-primary",
      "size": "50px"
    }
  })], 1), _c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center"
  }, [_c('p', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" VS ")])]), _c('b-col', {
    staticClass: "text-center",
    attrs: {
      "sm": "5"
    }
  }, [_c('b-avatar', {
    staticClass: "mb-2",
    attrs: {
      "src": (_vm$match2 = _vm.match) === null || _vm$match2 === void 0 ? void 0 : (_vm$match2$opponent = _vm$match2.opponent2) === null || _vm$match2$opponent === void 0 ? void 0 : (_vm$match2$opponent$p = _vm$match2$opponent.participant) === null || _vm$match2$opponent$p === void 0 ? void 0 : _vm$match2$opponent$p.avatar_image,
      "variant": "light-primary",
      "size": "50px"
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "flex-nowrap"
  }, [_c('b-col', {
    staticClass: "text-center",
    attrs: {
      "sm": "5"
    }
  }, [_c('h4', {
    staticClass: "mb-2 text-truncate"
  }, [_vm._v(" " + _vm._s((_vm$match3 = _vm.match) !== null && _vm$match3 !== void 0 && (_vm$match3$opponent = _vm$match3.opponent1) !== null && _vm$match3$opponent !== void 0 && _vm$match3$opponent.participant ? (_vm$match4 = _vm.match) === null || _vm$match4 === void 0 ? void 0 : (_vm$match4$opponent = _vm$match4.opponent1) === null || _vm$match4$opponent === void 0 ? void 0 : _vm$match4$opponent.participant.name : (_vm$match$opponent2 = _vm.match.opponent1) === null || _vm$match$opponent2 === void 0 ? void 0 : _vm$match$opponent2.name) + " ")])]), _c('b-col'), _c('b-col', {
    staticClass: "text-center",
    attrs: {
      "sm": "5"
    }
  }, [_c('h4', {
    staticClass: "mb-2 text-truncate"
  }, [_vm._v(" " + _vm._s((_vm$match5 = _vm.match) !== null && _vm$match5 !== void 0 && (_vm$match5$opponent = _vm$match5.opponent2) !== null && _vm$match5$opponent !== void 0 && _vm$match5$opponent.participant ? (_vm$match6 = _vm.match) === null || _vm$match6 === void 0 ? void 0 : (_vm$match6$opponent = _vm$match6.opponent2) === null || _vm$match6$opponent === void 0 ? void 0 : _vm$match6$opponent.participant.name : (_vm$match7 = _vm.match) === null || _vm$match7 === void 0 ? void 0 : (_vm$match7$opponent = _vm$match7.opponent2) === null || _vm$match7$opponent === void 0 ? void 0 : _vm$match7$opponent.name) + " ")])])], 1), ((_vm$match8 = _vm.match) === null || _vm$match8 === void 0 ? void 0 : _vm$match8.status_id) === _vm.MatchStatusType.COMPLETE ? _c('b-row', {
    staticClass: "flex-nowrap"
  }, [_c('b-col', {
    staticClass: "text-center",
    attrs: {
      "sm": "5"
    }
  }, [_c('div', [_c('strong', [_vm._v(_vm._s((_vm$match9 = _vm.match) === null || _vm$match9 === void 0 ? void 0 : _vm$match9.opponent1_score))])])]), _c('b-col'), _c('b-col', {
    staticClass: "text-center",
    attrs: {
      "sm": "5"
    }
  }, [_c('div', [_c('strong', [_vm._v(" " + _vm._s((_vm$match10 = _vm.match) === null || _vm$match10 === void 0 ? void 0 : _vm$match10.opponent2_score) + " ")])])])], 1) : _vm._e(), ((_vm$match11 = _vm.match) === null || _vm$match11 === void 0 ? void 0 : _vm$match11.status_id) === _vm.MatchStatusType.COMPLETE || ((_vm$match12 = _vm.match) === null || _vm$match12 === void 0 ? void 0 : _vm$match12.status_id) === _vm.MatchStatusType.DISPUTED ? _c('b-row', {
    staticClass: "\n    flex-nowrap"
  }, [_c('b-col', {
    staticClass: "text-center",
    attrs: {
      "sm": "5"
    }
  }, [_c('b-badge', {
    attrs: {
      "pill": "",
      "variant": _vm.resolveMatchResultBadgeColor((_vm$match13 = _vm.match) === null || _vm$match13 === void 0 ? void 0 : (_vm$match13$result = _vm$match13.result) === null || _vm$match13$result === void 0 ? void 0 : _vm$match13$result.opponent1_result)
    }
  }, [_vm._v(" " + _vm._s(_vm.resolveMatchResultBadgeValue((_vm$match14 = _vm.match) === null || _vm$match14 === void 0 ? void 0 : (_vm$match14$result = _vm$match14.result) === null || _vm$match14$result === void 0 ? void 0 : _vm$match14$result.opponent1_result)) + " ")])], 1), _c('b-col'), _c('b-col', {
    staticClass: "text-center",
    attrs: {
      "sm": "5"
    }
  }, [_c('b-badge', {
    attrs: {
      "pill": "",
      "variant": _vm.resolveMatchResultBadgeColor((_vm$match15 = _vm.match) === null || _vm$match15 === void 0 ? void 0 : (_vm$match15$result = _vm$match15.result) === null || _vm$match15$result === void 0 ? void 0 : _vm$match15$result.opponent2_result)
    }
  }, [_vm._v(" " + _vm._s(_vm.resolveMatchResultBadgeValue((_vm$match16 = _vm.match) === null || _vm$match16 === void 0 ? void 0 : (_vm$match16$result = _vm$match16.result) === null || _vm$match16$result === void 0 ? void 0 : _vm$match16$result.opponent2_result)) + " ")])], 1)], 1) : _vm._e(), _c('b-row', {
    staticClass: "mb-1 flex-nowrap"
  }, [_c('b-col', {
    staticClass: "text-center"
  }, [((_vm$match17 = _vm.match) === null || _vm$match17 === void 0 ? void 0 : _vm$match17.status_id) === _vm.MatchStatusType.READY || ((_vm$match18 = _vm.match) === null || _vm$match18 === void 0 ? void 0 : _vm$match18.status_id) === _vm.MatchStatusType.WAITING || ((_vm$match19 = _vm.match) === null || _vm$match19 === void 0 ? void 0 : _vm$match19.status_id) === _vm.MatchStatusType.LOCKED ? _c('b-badge', {
    staticClass: "ml-1",
    attrs: {
      "pill": "",
      "variant": "light-secondary"
    }
  }, [_vm._v(" In progress ")]) : _vm._e(), ((_vm$match20 = _vm.match) === null || _vm$match20 === void 0 ? void 0 : _vm$match20.status_id) === _vm.MatchStatusType.RUNNING ? _c('b-badge', {
    staticClass: "ml-1",
    attrs: {
      "pill": "",
      "variant": "light-success"
    }
  }, [_vm._v(" In progress ")]) : _vm._e(), ((_vm$match21 = _vm.match) === null || _vm$match21 === void 0 ? void 0 : _vm$match21.status_id) === _vm.MatchStatusType.COMPLETE ? _c('b-badge', {
    staticClass: "ml-1 p-1",
    attrs: {
      "pill": "",
      "variant": "light-success"
    }
  }, [_vm._v(" COMPLETE ")]) : _vm._e(), ((_vm$match22 = _vm.match) === null || _vm$match22 === void 0 ? void 0 : _vm$match22.status_id) === _vm.MatchStatusType.DISPUTED ? _c('b-badge', {
    staticClass: "ml-1 p-1",
    attrs: {
      "pill": "",
      "variant": "light-danger"
    }
  }, [_vm._v(" DISPUTED ")]) : _vm._e()], 1)], 1), _c('b-row', {
    staticClass: "mt-auto"
  }, [_c('b-col', {
    staticClass: "text-center"
  }, [_c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "primary"
    },
    on: {
      "click": _vm.handleMatchEditClick
    }
  }, [_vm._v(" Edit match ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }